<template>
    <div class="container">
        <transition
            name="change"
            mode="out-in"
            enter-active-class="animate__animated animate__fadeInRight"
        >
            <router-view></router-view>
        </transition>
    </div>
</template>

<script>
export default {
   data() {
      return {

      }
   },
   methods:{

   },
}
</script>

<style scoped>

</style>